import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import './verifyOtp.css';  // Ensure styles match login.css

const VerifyOtp = ({ baseUrl, setDriverId }) => {
    const [otp, setOtp] = useState("");
    const [loader, setLoader] = useState(false);
    const [otpValidity, setOtpValidity] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();
    const { hash, number } = location.state || {};

    const handleChange = (e) => {
        if (!otpValidity) setOtpValidity(true)
        setOtp(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // const otpRegex = /^\d{6}$/;
        // if (!otpRegex.test(otp)) {
        //     setOtpValidity(false);
        //     return;
        // }

        try {
            if (!navigator.onLine) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You are currently offline. Please check your internet connection and try again.',
                });
                return;
            }

            setLoader(true);

            const response = await fetch(`${baseUrl}/authentication/verify-otp`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ otp, hash, number })
            });

            const data = await response.json();
            console.log('dataVerifyOtp', data);

            if (data.statusCode === 200) {
                setDriverId(data.data[0].p_id);
                localStorage.setItem('driverEcoID', data.data[0].p_id);
                navigate('/');
            } else {
                setOtpValidity(false);
                // Swal.fire({
                //     icon: 'error',
                //     title: 'Invalid OTP',
                //     text: 'The OTP you entered is incorrect. Please try again.',
                // });
            }
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong on the server!',
            });
        } finally {
            setLoader(false);
        }
    };

    return (
        <div className="verify-otp-page">
            {loader ? <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div> : (
                <div className="container-fluid">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-9 col-sm-8 col-md-6 col-lg-3 card d-flex flex-column justify-content-around verify-otp-card">
                            <div className="verify-otp-ecowrap-logo">
                                <img src="asset/ecowrap-logo.png" alt="Ecowrap logo" />
                            </div>
                            <div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="otp"
                                        value={otp}
                                        onChange={handleChange}
                                    />
                                    <label>OTP</label>
                                </div>
                                {!otpValidity && <div style={{ color: 'red', fontSize: '12px' }}>
                                    Invalid OTP.
                                </div>}
                            </div>
                            <div className="verify-otp-submit">
                                <button className="btn btn-primary" onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VerifyOtp;
