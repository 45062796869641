import React, { useState, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import Swal from 'sweetalert2';
import socketIOClient from 'socket.io-client';
import './home.css';

const SOCKET_SERVER_URL = 'https://apis.ecowrap.in/b2g/driver/live-location';
const GOOGLE_ROADS_API_URL = 'https://roads.googleapis.com/v1/snapToRoads'; // Roads API endpoint
const GOOGLE_API_KEY = 'AIzaSyBqbSNw8jtcU9MjyqXFgKdP9l9UeWqaTz8'; // Replace with your Google API key

const Home = ({ onLogout, driverId }) => {
    const [fullBoundary, setFullBoundary] = useState([]);
    const [boundary, setBoundary] = useState([]);
    const [pathData, setPathData] = useState([]);
    const [currentPath, setCurrentPath] = useState([]); // State for current path
    const [mapInstance, setMapInstance] = useState(null);
    const [mapsInstance, setMapsInstance] = useState(null);
    const [center, setCenter] = useState({ lat: 26.8495, lng: 75.7575 });
    const [zoom, setZoom] = useState(14);
    const [shouldCenterMap, setShouldCenterMap] = useState(true);
    const [isEmitting, setIsEmitting] = useState(false);
    const [status, setStatus] = useState('Offline');
    const socketRef = useRef(null);
    const locationWatcherRef = useRef(null);
    const userMarkerRef = useRef(null);
    const [userLocation, setUserLocation] = useState(null);

    useEffect(() => {
        socketRef.current = socketIOClient(SOCKET_SERVER_URL);
        socketRef.current.emit('join', driverId);

        return () => {
            if (locationWatcherRef.current) {
                navigator.geolocation.clearWatch(locationWatcherRef.current);
            }
            socketRef.current.disconnect();
        };
    }, [driverId]);

    const fetchBoundaries = async () => {
        try {
            const response = await fetch(`https://apis.ecowrap.in/b2g/driver/dashboard/${driverId}`, {
                method: 'GET',
            });
            if (!response.ok) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error fetching boundaries',
                    text: 'Something went wrong while fetching boundaries. Please try again later.',
                });
                throw new Error(`Error: ${response.status}`);
            }

            const data = await response.json();
            if (data?.statusCode === 200 && Array.isArray(data?.data) && data?.data.length > 0) {
                const firstDataItem = data.data[0];

                const boundaryData = (firstDataItem.boundry || []).map((point) => ({
                    lat: !isNaN(point.x) ? point.x : null,
                    lng: !isNaN(point.y) ? point.y : null,
                })).filter(point => point.lat !== null && point.lng !== null);

                setBoundary(boundaryData);
                if (boundaryData.length > 0) setCenter(boundaryData[0]);

                const fullBoundaryData = (firstDataItem.fullBoundary || []).map((point) => ({
                    lat: !isNaN(point.x) ? point.x : null,
                    lng: !isNaN(point.y) ? point.y : null,
                })).filter(point => point.lat !== null && point.lng !== null);

                setFullBoundary(fullBoundaryData);

                const safePathData = (firstDataItem.path || []).map((segment) =>
                    segment.map((point) => ({
                        lat: !isNaN(point[1]) ? point[1] : null,
                        lng: !isNaN(point[0]) ? point[0] : null,
                    }))
                ).filter(segment => segment.length > 0);

                setPathData(safePathData);
                //firstDataItem.currentRoute

                const currentRouteData = (firstDataItem.currentRoute || []).map((point) => ({
                    lat: !isNaN(point.y) ? point.y : null,
                    lng: !isNaN(point.x) ? point.x : null,
                })).filter(point => point.lat !== null && point.lng !== null);

                // Send currentRouteData to Google Roads API
                // const snappedRoute = await snapToRoads(currentRouteData);
                setCurrentPath(currentRouteData);
            } else {
                throw new Error('Invalid data structure or empty response.');
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error fetching boundaries',
                text: 'Something went wrong while fetching boundaries. Please try again later.',
            });
            console.error('Error fetching boundaries:', error);
        }
    };


    useEffect(() => {
        fetchBoundaries();
    }, [driverId]);

    const startEmittingLocation = () => {
        if (!navigator.geolocation) {
            Swal.fire({
                icon: 'error',
                title: 'Geolocation Not Supported',
                text: 'Geolocation is not supported by this browser.',
            });
            return;
        }

        setIsEmitting(true);
        setStatus('Live');

        locationWatcherRef.current = navigator.geolocation.watchPosition(
            async (position) => {
                const { latitude, longitude, accuracy } = position.coords;
                const newLocation = { lat: latitude, lng: longitude };


                // Snap to road using Google Roads API




                if (accuracy <= 40 && socketRef.current) {
                    setUserLocation(newLocation);
                    const snappedLocations = await snapToRoads([newLocation]);
                    if (shouldCenterMap) {
                        setCenter(snappedLocations[0]);
                    }
                    setCurrentPath((prevPath) => [...prevPath, ...snappedLocations]);
                    socketRef.current.emit('driverLocation', {
                        lat: latitude,
                        lng: longitude,
                    });
                }
            },
            (error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Geolocation Error',
                    text: 'Unable to retrieve your location. Please ensure location services are enabled.',
                });
                console.error('Error getting location:', error);
            },
            {
                maximumAge: 1000,
                timeout: 10000,
                enableHighAccuracy: true,
            }
        );
    };

    const stopEmittingLocation = () => {
        if (locationWatcherRef.current) {
            navigator.geolocation.clearWatch(locationWatcherRef.current);
            setIsEmitting(false);
            setStatus('Offline');
        }
    };

    const snapToRoads = async (path) => {
        try {
            const pathParam = path.map(p => `${p.lat},${p.lng}`).join('|');

            const response = await fetch(`${GOOGLE_ROADS_API_URL}?path=${pathParam}&interpolate=true&key=${GOOGLE_API_KEY}`);
            const data = await response.json();
            // console.log('response', pathParam, response, data)

            if (data.snappedPoints) {
                return data.snappedPoints.map((point) => ({
                    lat: point.location.latitude,
                    lng: point.location.longitude,
                }));
            }
            return path;
        }
        catch (e) {
            console.error('error in snapToRoads func', e)
        }
    };

    useEffect(() => {
        if (mapInstance && mapsInstance && userLocation) {
            if (userMarkerRef.current) {
                userMarkerRef.current.setPosition(userLocation);
            } else {
                userMarkerRef.current = new mapsInstance.Marker({
                    position: userLocation,
                    map: mapInstance,
                    icon: {
                        path: mapsInstance.SymbolPath.CIRCLE,
                        scale: 8,
                        fillColor: 'blue',
                        fillOpacity: 1,
                        strokeWeight: 0,
                    },
                });
            }

            if (shouldCenterMap) {
                mapInstance.panTo(userLocation);
            }
        }
    }, [mapInstance, mapsInstance, userLocation, shouldCenterMap]);

    useEffect(() => {
        if (mapInstance && mapsInstance) {
            if (fullBoundary.length > 0) {
                const fullBoundaryPath = fullBoundary.map((point) => new mapsInstance.LatLng(point.lat, point.lng));
                new mapsInstance.Polygon({
                    paths: fullBoundaryPath,
                    map: mapInstance,
                    fillColor: 'transparent',
                    strokeColor: 'gray',
                    strokeOpacity: 1,
                    strokeWeight: 4,
                });
            }

            if (boundary.length > 0) {
                const boundaryPath = boundary.map((point) => new mapsInstance.LatLng(point.lat, point.lng));
                new mapsInstance.Polygon({
                    paths: boundaryPath,
                    map: mapInstance,
                    fillColor: 'transparent',
                    strokeColor: 'blue',
                    strokeOpacity: 1,
                    strokeWeight: 4,
                });
            }

            if (pathData.length > 0) {
                pathData.forEach((segment) => {
                    const formattedSegment = segment.map((point) => new mapsInstance.LatLng(point.lat, point.lng));
                    new mapsInstance.Polyline({
                        path: formattedSegment,
                        map: mapInstance,
                        strokeColor: 'red',
                        strokeOpacity: 1,
                        strokeWeight: 4,
                    });
                });
            }

            if (currentPath.length > 0) {
                const formattedCurrentPath = currentPath.map((point) => new mapsInstance.LatLng(point.lat, point.lng));
                new mapsInstance.Polyline({
                    path: formattedCurrentPath,
                    map: mapInstance,
                    strokeColor: 'green',
                    strokeOpacity: 1,
                    strokeWeight: 4,
                });
            }
        }
    }, [mapInstance, mapsInstance, fullBoundary, boundary, pathData, currentPath]);

    const handleApiLoaded = (map, maps) => {
        setMapInstance(map);
        setMapsInstance(maps);
    };



    return (
        <div className="home">
            <div className="navbar">
                <p>Status: {status}</p>
                <div className='btn-navbar-div'>
                    <button className="btn btn-success" onClick={startEmittingLocation} disabled={isEmitting}>
                        Go Live
                    </button>
                    <button className="btn btn-danger" onClick={stopEmittingLocation} disabled={!isEmitting}>
                        End
                    </button>
                    <button className="btn btn-danger" onClick={onLogout}>Logout</button>
                </div>
            </div>
            <div className="map-container">
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyBqbSNw8jtcU9MjyqXFgKdP9l9UeWqaTz8' }}
                    center={center}
                    zoom={zoom}
                    options={{
                        draggable: true,
                        zoomControl: true,
                        rotateControl: true,
                        gestureHandling: 'greedy',  // Enables both scroll and touch gestures
                        mapTypeControl: false, // Hides map type control
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                />
            </div>
        </div>
    );
};



export default Home;
