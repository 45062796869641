import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import './login.css';

const Login = ({ baseUrl }) => {
    const [credential, setCredential] = useState({ number: "" });
    const [loader, setLoader] = useState(false);
    const [credentialValidity, setCredentialValidity] = useState(true);

    const navigate = useNavigate();

    const handleChange = (e) => {
        setCredential({ ...credential, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const numberRegex = /^[6-9]\d{9}$/;

            if (!numberRegex.test(credential.number)) {
                setCredentialValidity(false);
                return;
            }

            if (!navigator.onLine) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You are currently offline. Please check your internet connection and try again.',
                });
                return;
            }

            setLoader(true);

            const response = await fetch(`${baseUrl}/authentication`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ number: credential.number })
            });

            const data = await response.json();
            console.log('data', data);

            if (data?.statusCode === 200) {
                const hash = data?.data[0];
                navigate('/verifyOtp', { state: { hash, number: credential.number } });
            } else {
                setCredentialValidity(false);
            }
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong on the server!',
            });
        } finally {
            setLoader(false);
        }
    };

    return (
        <div className="login-page">
            {loader ? <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div> : (
                <div className="container-fluid">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-9 col-sm-8 col-md-6 col-lg-3 card d-flex flex-column justify-content-around login-page-card">
                            <div className="login-page-ecowrap-logo">
                                <img src="asset/ecowrap-logo.png" alt="Ecowrap logo" />
                            </div>
                            <div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="number"
                                        value={credential.number}
                                        onChange={handleChange}
                                    />
                                    <label>Contact Number</label>
                                </div>
                                {!credentialValidity && <div style={{ color: 'red', fontSize: '12px' }}>
                                    Incorrect Phone number
                                </div>}
                            </div>
                            <div className="login-page-submit">
                                <button className="btn btn-primary" onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Login;
