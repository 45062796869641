import { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Login from './login';
import VerifyOtp from './verifyOtp';
import Home from './home';  // Import the Home component
import './App.css';

function App() {
  const baseUrl = 'https://apis.ecowrap.in/b2g/driver';

  // Retrieve driver ID from localStorage
  const [driverId, setDriverId] = useState(localStorage.getItem('driverEcoID') || null);
  // const [driverId, setDriverId] = useState(16)
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!driverId && location.pathname !== '/verifyOtp' && location.pathname !== '/login') {
      navigate('/login');
    }
  }, [driverId, navigate, location]);

  const handleLogout = () => {
    setDriverId(null);
    localStorage.removeItem('driverEcoID'); // Remove ID from localStorage on logout
  };

  return (
    <div className="App">
      <Routes>
        {/* Login route */}
        <Route path="/login" element={<Login baseUrl={baseUrl} setDriverId={setDriverId} />} />

        {/* Route for OTP verification */}
        <Route path="/verifyOtp" element={<VerifyOtp baseUrl={baseUrl} setDriverId={setDriverId} />} />

        {/* Home route (if authenticated) */}
        <Route path="/" element={driverId ? <Home onLogout={handleLogout} driverId={driverId} /> : <Navigate to="/login" />} />

        {/* Catch-all route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
